<template>
  <div>
    <div style="margin: 20px 0 0 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="role-main">
      <Invitationlist></Invitationlist>
    </div>
  </div>
</template>

<script>
import Invitationlist from './components/mySite/invitationList.vue'
export default {
  name: "role",
  data(){
    return{

    }
  },
  components:{
    Invitationlist
  },
}
</script>

<style scoped lang="scss">
.role-main{
  margin: 20px;

  .role-main-cont{
    padding:20px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
    border-radius: 4px;
  }

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }

}
</style>
<style scoped>

.link-cont{
  margin-top: 20px;
  border:1px solid #dcdee2;
  border-radius: 4px;
  min-height: 60px;
}
</style>
